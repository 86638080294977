<script>
  import DarkMode from "./DarkMode.svelte";
</script>

<nav class="navbar neu-flat">
  <a href="https://ihtfy.com" class="navbar-brand">IHTFY</a>
  <div class="navbar-menu ml-auto lh-normal">
    <a
      class="navbar-link neu-flat rounded-1"
      target="_blank"
      href="https://github.com/IHTFY/raven">Source</a
    >
    <DarkMode />
  </div>
</nav>
